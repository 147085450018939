import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/kszol/projects/noeadogatsby/src/components/layout/default-page-layout.tsx";
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Gallery from '../components/layout/gallery';
import Youtube from '../components/layout/youtube';
import thumbData from '../images/erreforditjuk/video/data.json';
import LightboxPageLayout from '../components/layout/lightbox-page-layout';
export const query = graphql`
  query {
    allFile(
      filter: {
        relativeDirectory: { eq: "erreforditjuk/2020" }
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
      }
    ) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            breakpoints: [210, 270, 370, 572, 720]
          )
          original {
            width
            height
          }
        }
      }
    }
    allJson(filter: { graphql: { eq: "EF2020" } }) {
      nodes {
        resources {
          index
          title
          image {
            base
          }
        }
      }
    }
    video: allFile(
      filter: {
        relativeDirectory: { eq: "erreforditjuk/video" }
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
      }
    ) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            breakpoints: [210, 270, 370, 572, 720]
          )
          original {
            width
            height
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Link = makeShortcode("Link");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = LightboxPageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{`Erre fordítjuk, amit adsz (2020)`}</h1>
    <h2>{`Noé állatotthon`}</h2>
    <p>{`A közel 3 évtizede működő Magyarország legnagyobb állatotthonában 1992 óta végezzük a bajba jutott, megkínzott, balesetet szenvedett állatok mentését. Jelenleg 2 telephelyen közel 10 hektáron több mint 1.200 kutya, cica, malac, kecske, juh, nyuszi, tengerimalac, csirke, kacsa, liba, páva, vaddisznó, mosómedve, ormányos medve, borz, ló, póni, szamár, láma, díszmadár, galamb, varjú, egerészölyv, teknős, hüllő és szarvasmarha és még sok másféle állatfaj átmeneti, vagy - sok esetben - végleges otthona.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(0, 3)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`2020 a mi életünkben is hatalmas kihívásokat tartogatott. A COVID-19 világjárvány miatt az év nagy részében zárt kapuk mögött kellett túlélnünk. Elmaradtak a rendezvények, az adománygyűjtési lehetőségek szinte teljesen megszűntek. Az örökbefogadások természetesen nem álltak meg, hiszen a bajbajutott állatok száma sem csökkent, sőt a második hullámra erősen megnövekedett.`}</p>
    <p>{`Ebben a nehéz időszakban, amikor a tárgyi adományaink extra mód visszaestek és a pénzbeli támogatások is jelentősen csökkentek még fontosabb, hogy felelősen gondolkozzunk. Sosem felejtkezhetünk meg arról a közel 1300 állatról, akik éppen a gondozásunkban vannak, akikért egy életre felelősséget vállaltunk. Továbbra sem adunk le a színvonalból, biztos hátteret, a lehető legjobb elhelyezést és legmagasabb szintű állatorvosi ellátást kapják a megmentett állataink.`}</p>
    <p>{`A 2020-as év a pandémia, a parvo, a szívférgesség és a giardia négyesésnek fenyegetésében telt. Továbbra is a bekerülő kisállatok, almok – legyen szó kutyáról vagy kiscicáról – érintett a parvoban. A kismacskák köznyelven parvonak nevezett panleukopenia vírusos megbetegedése pontosan ugyanolyan rettegett betegsége, mint a kutyaparvo. Hatalmas a veszteségi arány, a gyógyuláshoz folyamatos orvosi kontroll, aktív kezelés és fáj ezt mondani, de szerencse is kell. Havi állatorvosi költségeink jelentős részét a parvoval való küzdelem teszi ki, nem beszélve a veszélyről ami a többiekre leselkedik, a fertőtlenítési protokoll folyamatos fejlesztéséről, karanténozás nehézségéről.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(3, 6)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Szintén behurcolt betegség, mely mind a kutyák mind a cicák nagyon nagy részét érinti a giardia. Ez egy, a bélcsatornában élősködő egysejtű parazita. Véres hasmenés, rosszullét, legyengült immunrendszer esetében rosszullét, sőt babáknál akár halál is lehet a vége. Kiirtani nagyon nehéz, az orvosság drága, a kezelési protokoll nehéz.`}</p>
    <p>{`Már eljutottunk oda, hogy 10 bekerülő kutyából 4-5 biztosan érintett a szív és/vagy a bőrférgességben. 2020-ban 5 kutyánk esett át szívféreg eltávolító műtéten. Rengetegen állnak aktív kezelés alatt. De nagy sikereink is vannak, sok-sok gyógyult kutyánk bizonyítja, hogy megéri a teljes körű kivizsgálás és a legmodernebb protokollok alkalmazása.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(6, 9)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Megállás nélkül érkeztek a bajbajutott, balesetes, beteg állatok, az ország egész területéről. Végtagtörések, tépett, szakított, elfertőződött sebek, kopasz, vérző, szenvedő állatok. Mind – mind az emberi felelőtlenség, gonoszság, elhanyagolás áldozatai. Naponta kapjuk a kétségbeesett segítségkéréseket magánszemélyektől, állatvédőktől. Látjuk az utak szélén elgázoltakat, a gyepmesteri telepeken halálra ítélteket, a születésük pillanatában bajba kerülő felesleges szaporulatokat, a tulajdonosaik által magukra hagyott, elhanyagolt megkínzottakat. Szinte lehetetlen lépésttartani, de megpróbáljuk. Havi állatorvosi, gyógyszer és gyógytáp számláink meghaladjá a 6 millió forintot.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(9, 12)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Több új gerincsérült, béna kutyánk és cicánk is érkezett. Mindenki túlesett MR vizsgálaton és gerincműtéten. Volt, akin nem tudtunk segíteni, de többen talpra álltak és már 4 lábon szaladgálnak és a kerekeskocsi flottánk is bővült 4 új taggal. Jelenleg 6 kiskocsis fogadná a látogatókat, ha jöhetnének látogatók.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(12, 15)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Össze sem tudjuk számolni mennyi „nyakas” kutyánk érkezett. Azokat nevezzük így, akiknek a lénc belenőtt a nyakába, akiket valamikor valószínűleg gyerekként láncravertek és azóta tengetik így az életüket. Volt olyan megdöbbentő eset, akinek a derekába nőtt bele a lecsatolhatatlan lánc.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(15, 18)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Óriási elhanyagolt daganatok, eleve vesztett szituációból indulunk. Ezek nagy része egy időben elvégzett ivartalanítással ki sem fejlődött volna, vagy amíg még kisebbek sikerrel el lehet távolítani azokat. De mire hozzánk kerülnek, addigra már csak minimális esélyük van – természetesen ezt is megkapják.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(18, 21)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`És a lövések, vágások, baltacsapások… Fejbelőtt kutyák, elvágott nyakú kutyák, baltával fejbevert állatok, nyíllal átlőtt macskák, légpuskával megsebesített madarak. Célzott, hidegvérű, gonosz, aljas bántalmazások. Minden hétre jutott egy, vagy inkább több.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(21, 24)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(24, 27)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(27, 30)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`És persze ha 2020, akkor szaporító és gyűjtögető telepek felszámolása, összefogás és együttműködés az állatvédők és a hatóságok között. 2 óriástelep felszámolás (Nyírbogát és Karcag), 5 gyűjtögető telep felszámolás, viadalra szaporított és harcoltatott állattelepek felszámolása. Zsákba kötött állatok, tömegesen magukra hagyott állatok. Mind – mind olyan történet, melyet egyedül egy szervezet képtelen lett volna felelősen megoldani. Mindannyiszor összefogtunk, együtt mentünk, közösen dolgoztunk – együtt állatvédők és hatóságok. Ezek a telepek azok a helyek, ahol egyszerre több száz állat szenved iszonyú körülmények között. Saját ürülékükben, betegen, egymást széttépve, folyamatosan fertőzéseknek kitéve. A mentések szinte mindig flottul, gyorsan és profin mennek. Azután kezdődik a hosszú és kétséges eljárás. Van olyan ügyünk, ahol közel 3 éve zajlik az ügy… nem tudunk mit mondani az állatokra vigyázó ideiglenes befogadóknak. De nem adjuk fel. Továbbra is összekapaszkodva nekimegyünk ezeknek a telepeknek!`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(30, 33)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Sokan kérdezik, hogy bírunk el ennyi szenvedéssel, ennyi fájdalommal? Nehezen, de rengeteg erőt tudunk meríteni a sok csodából, sikerből, gyógyulásból, boldog gazdisodásból. Csodálatos látni csillogó szőrrel egy olyan állatot aki sebesen, véresen kopaszon érkezett. Látni talpra állni a bénultat, töröttet. Látni csóválni a sikítva rettegőt. Látni a boldog gazdis beszámolókat esélytelenekről, vakokról, problémásokról.`}</p>
    <p>{`Bár a világjárvány miatt nagyon figyelnünk kell minden kiadásra, tervezzük a költségeinket, hiszen rengeteg állatért tartozunk felelősséggel, mégis sikerült jó néhány fejlesztést megvalósítanunk. Tovább épült, szépült, modernizálódott az állatotthon. Ezek a fejlesztések rendkívül fontosak, egyrészről az állataink kényelme, jóléte és biztonsága szempontjából, másrészről a sikeres mentések kivitelezésében, harmadrészről visszavárjuk a látogatókat és szeretnénk, hogy ők is jól érezzék magukat nálunk.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(33, 36)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Tovább fejlesztettük a kutyás baba-mama karantén kennelsorunkat. EZ a legkényesebb hely az állatotthonban, hiszen az oltatlan csöppségekre rengeteg veszély leselkedik. Ezért építettük tavaly és ezért fejlesztettük most tovább. Szép fedést kapott az egész karantén részleg, modern világítás teszi lehetővé a legmagasabb szintű ellátásukat és minden kölykös karanténkennel kapott modern, lezárható fólia fedést. Egyrészről a fertőtlenítéshez, másrészről hidegben így még komfortosabban tudjuk elhelyezni őket is.`}</p>
    <p>{`Beszereztünk egy nagy teljesítményű ózongenerátort a leghatékonyabb fertőtlenítéshez. Mióta megérkezett szinte folyamatosan munkában van. A gyengélkedő részlegeket, karantén kenneleket, kórházi helyiségeket csírátlanítja, fertőtleníti. Hatalmas előrelépés ez a fertőző betegségek elleni küzdelmünkben.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(36, 39)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Támogatónknak hála hozzájuthattunk több modern, ledtechnikával működő világítótesthez, így elkezdődhetett az állatotthon helyiségeinek világítás modernizációja. Aki kezelt már állatot félhomályban, az pontosan tudja, hogy mennyire fontos lépés ez.`}</p>
    <p>{`További modern, hőszigetelt, tisztántartható, fertőtleníthető kutyaházakat sikerült munkába állítanunk. Támogatóinknak hála ezek közül több fűthető is, így mégtöbb érzékeny, idősödő, vagy kölyök kutyánk melegben lehet a hideg időszakokban is.`}</p>
    <p>{`Megújult, megszépült, elképesztően modern lett az időskutya melegedőnk is, NOÉs becenevén az Öregedő. Ebben a házban kerülnek elhelyezésre azok az idős kutyáink akik nyáron már nem tudják elviselni a kánikulát, télen nem bírnák ki a hideget, vagy krónikus betegségük miatt folyamatos ápolásra, kezelésre szorulnak. Csodaszép, takarítható, kényelmes, világos lett.`}</p>
    <p>{`Kialakításra került egy új részleg, mely a RandeVau nevet kapta. Amikor megálmodtuk még nem gondoltuk, hogy a vírus miatt milyen fontos része lesz az életünknek. Ez egy olyan biztonságos szép, elkülönített terület, ahol a gazdijelöltek és leendő kutyusaik szabadon, póráz nélkül, nem zavartatva a többi állattól ismerkedhetnek. Direkt egy olyan részen alakítottuk ki, mely kívülről megközelíthető, hogy a család már meglévő kutyája is be tudjon menni. Most, amikor nem fogadhatunk látogatókat, hatalmas segítség lett ez a hely az ismerkedésben és sikeres örökbeadásokban.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(39, 42)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`És mivel nagyon várjuk vissza a látogatókat, így rájuk is gondoltunk. Megújult a NOÉ játszótere, új izgalmas játékok, mászófal, magasles távcsővel várja a kicsiket és nagyokat. Valamint MacsKaLand részlegünkben kialakítottunk egy könyvsarkot, ahol könyvek, játékok, társasjátékok várnak arra, hoy végre valaki a kezébe vehesse őket.`}</p>
    <p>{`Természetesen tele vagyunk most is tervekkel, van amit már évek óta görgetünk magunk előtt, de muszáj mérlegelnünk, felelősen gondolkoznunk. Befejezésre vár a rehabilitációs kennelsorunk, reményeink szerint tavasszal megindulhat a BullBox részlegünk felújítása. Tovább halad a karanténrészleg megújulása, most a felnőtt kennelek kerülnek sorra. Külső karanténunk is felújításra vár, illetve tovább szeretnénk bővíteni a macskás elkülönítőt is, hogy minél több bársonytalpút tudjunk megmenteni.`}</p>
    <p>{`Sajnos a NOÉ Állatotthon Alapítvány menhelysegítő programjára is nagy hatással volt a világjárvány. A korlátozások miatt nem tudtunk menni a kisebb menhelyekre személyesen segítőnapokat tartani. De azért nem engedtük el a kezüket, egész évben osztottuk az adományokat, élelmet számukra. Ha azt mondjuk, hogy minket nehéz helyzetbe hozott a járvány, akkor a vidéki kicsiket még jobban bajba sodorta. Sok helyen az iskolák, óvodák bezárásával, a közétkeztetés megszűnésével egyik napról a másikra megszűnt az élelem ellátás. Azonnal reagáltunk és országosan juttatunk a saját készleteinkből. Támogatóinknak hála ezt egész évben fenn tudtuk tartani. Nem csak élelem, de egyéb sok hasznos dolog, gyógyászati eszközök, használati tárgyak kerültek olyan helyekre, ahol azonnal szükség volt rájuk.`}</p>
    <p>{`2020-ban újabb elemmel bővült a program, a MagnetBank KAP pályázatán sikerült forrást szereznünk a „zöldebb menhelyekért” projektünkhöz. Ennek keretében a saját faiskolánkból 170 fűzcsemetét tudtunk adni országosan 11 menhelynek. A gyorsannövő fák már 1-2 éven belül hathatós segítséget fognak nyújtani az árnyékolásban, a környezetvédelmi értéke mellett.`}</p>
    <p>{`A NOÉ Állatotthon Vas megyei 100 férőhelyes kutyamenhelye is csúcsrajárt 2020-ban, 80 új kutyát mentettünk a környék rászorulói közül, illetve sok-sok kutyusunk került itt elhelyezésre rehabilitációs céllal. A csodálatos, csendes, nyugodt környezetben annyira sikeresek az érzékeny lelkű, extra félős, vagy viselkedésproblémás kutyák rehabilitációja, hogy sok esetben az évek óta „beragadt” kutyáknak néhány hónap után sikeresen találunk örökbefogadókat.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(42, 45)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Természetesen továbbra is az állatok mentése a fő tevékenységünk. Csak kutya és cica 1100 felett érkezett a NOÉ Állatotthon gondozásába 2020-ban. És közel 1000 állatot sikerült új családba juttatnunk. A kutyusok és cicák mentésén túl természetesen egyéb állatok is érkeztek, kecskék, juhok, disznók, galambok, baromfik, szarkák, varjak, lovak… felsorolni is nehéz. Ők gyógyulásuk, rehabilitálásuk után vagy szabadon lettek engedve, vagy öröklakóként élnek boldogan nálunk. Úgyhogy 2020-ban bőven meghaladta a mentett állatok száma az 1500-at. Azt vettük észre, hogy már nincs kiscica, kiskutya szezon, megállás nélkül érkeznek a legkisebbek is. Minden kéz, minden sarok, minden doboz rejt egy-egy cumistát.`}</p>
    <p>{`Volt sok szívszorító eset, amikor magunk sem hittük a látottakat. A bekerülő és a nálunk élő védenceinkről szóló hírek elolvashatóak ITT: `}<a href="http://www.noeallatotthon.hu/index.php?pid=2&tid=4" target="_blank" rel="noreferrer">{`Történetek Állatainkról`}</a></p>
    <p>{`2021`}{`.`}{` január 1-én az alapítvány védenceinek száma 1.278 volt.`}</p>
    <p>{`Hogy a 2020-as igen nehéz év ellenére is megdöntöttük a saját befogadási és örökbeadási számainkat, hogy tovább tudtunk épülni, szépülni, hogy ennyi állat meggyógyulhatott és kaphatott új esélyt, ezt nagy részben köszönhetjük azoknak a támogatóinknak, akik legutóbb is nekünk ajánlották fel személyi jövedelemadójuk 1%-át. Hálásan köszönjük Nektek, hogy ismét mellénk álltatok és a felajánlásotokkal újra biztos alapot teremtettek a a jövőbeli munkánk hoz.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(45, 48)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p><strong parentName="p">{`MERT A CSODÁKAT EGYÜTT CSINÁLJUK!`}</strong></p>
    <p>{`Sok minden megvalósult, sok dolog még álom. De érezve a támogatást, a bizalmat, nem dobjuk el az álmainkat a terveinket. Szépen sorban haladunk, tervezünk, számolunk. És talán egyszer ennek a listának is a végére érünk. De addig még egy-két apróságot megvalósítanánk. Ami a legfontosabb, hogy a megkezdett úton haladva szeretnénk biztosítani a védenceik biztos jövőjét, a kényelmüket, jólétüket, egészségügyi ellátásukat.`}</p>
    <p><strong parentName="p">{`Köszönjük mindenkinek, aki hozzájárult a munkánkhoz, önkéntesként, támogatóként, cégként vagy magánszemélyként, a személyi jövedelemadója 1%-nak felajánlásával, adománnyal, virtuális örökbefogadással, örökbefogadással, ideiglenes befogadással, az adomány vonal hívásával, vagy akár csak egy megosztással!`}</strong></p>
    <p><strong parentName="p">{`CSAK VELETEK SIKERÜLHET!`}</strong></p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(48, 51)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p><strong parentName="p">{`KÖSZÖNJÜK!`}</strong></p>
    <p>{`Hála Nektek, akik Noé Állatotthon Alapítvány védencei javára ajánlottátok fel a személyi jövedelemadótok 1 százalékát, újabb 1 évig biztosítva van közel 1.300 védencünk – az 1100 új bekerülő - ellátása, gyógyítása, gazdásítása vagy repatriálása. Közel három évtizede segítetek minket ezen az úton, érezhetjük a támogatásotokat, szereteteket, hogy velünk vagytok a nehéz időkben, a nehéz eseteknél is. `}<strong parentName="p">{`Hogy velünk együtt hisztek a CSODÁkban, az ÉLETben és együtt megvalósíthatjuk őket!`}</strong></p>
    <p>{`A NAV tájékoztatása szerint a 2020-as személyi jövedelemadók 1%-os felajánlásából, 85,5 millió forint összegben, közel 14 ezer felajánló rendelkezett úgy, hogy biztos hátteret nyújt az Alapítvány gondozásában élő közel 1.300 állatnak a következő egy évben is.`}</p>
    <p>{`Tudjuk ismét hatalmas összeget bíztatok ránk. És mi a következő egy éveben - ugyanúgy ahogy az elmúlt 29 évben - felelősséggel fogjuk kezelni. Továbbra is kizárólag az állatok segítésére, mentésére, ÉLETére fordítunk minden fillért! És ahogy már nagyon sok éve, nem "csak" az 1.300 NOÉs védenc, az évente 1.100 megmentett új állat részesül mindebből, de folytatjuk a kisebb menhelyek segítését, oltást, chipet, élelmet, ivartalanítást, parazitamentesítést, fertőtlenítőt, facsemetéket és még nagyon sok egyéb támogatást kap további rászoruló sokezer állat. Továbbra is országosan vállaljuk sérült, beteg, balesetes, életveszélyben lévő állatok gondozásunkba vételét.`}</p>
    <p>{`Oktatási és érzékenyítő programjaink mindenki számára elérhető, ingyenes oktatási programunk szeretettel várja óvodások, iskolások, csoportok látogatását és mi magunk is rendszeresen kijárunk tanintézményekbe előadást tartani a felelős állattartásról, a természet és az állatok tiszteletéről, szeretetéről.`}</p>
    <p>{`Bár ez az összeg egy biztos háttérországot jelent, nagyjából a fele annak, amennyi a biztonságos működésünkhöz, a mentésekhez, az állatok legmagasabb szintű ellátásához szükséges. A másik felét a következő egy évben is közösen kell előteremtenünk.`}</p>
    <p>{`Továbbra is reméljük, hogy velünk és a védenceinkkel maradtok, akiknek csak a közös segítségünk jelenti a túlélést.`}</p>
    <div className="w-full lg:w-auto grow flex flex-col mx-2 p-4 bg-white not-prose">
  <Youtube resources={thumbData.resources} nodes={props.data.video.nodes} mdxType="Youtube" />
    </div>
    <p>{`Korábbi beszámolóink:`}</p>
    <p>
  <Link to="/erre-forditjuk-2019" mdxType="Link">Erre fordítjuk - 2019</Link>
    </p>
    <p>
  <Link to="/erre-forditjuk-2018" mdxType="Link">Erre fordítjuk - 2018</Link>
    </p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      